import { useEffect, useState } from 'react';

const useCuisineFilters = (restaurants) => {
  const [cuisineFilters, setCuisineFilters] = useState([]);
  useEffect(() => {
    const filters = [];
    restaurants.edges.map(({ node }) => {
      node.cuisines.edges.map(({ node: cuisineNode }) => {
        if (cuisineNode.id && cuisineNode.active) {
          filters[`${cuisineNode.id}`] = cuisineNode;
        }
        return cuisineNode;
      });
      return node;
    });
    setCuisineFilters(filters);
  }, []);
  return cuisineFilters;
};

export default useCuisineFilters;
